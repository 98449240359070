import React, { useState, useEffect, useMemo } from 'react'
import { Container, Row, Col, Breadcrumb, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import "./style.css"
// //img
import subscriptionBG from '../../../../assets/images/subscriptionBG.jpg'
import LoadingGIF from '../../../../assets/regal_images/regal_logo.png'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import RazorpayButton from '../../../../razorpay/razorpayButton'
import { check_promocode } from '../../../../actions/PaymentActions'
import { PROMOCODE } from '../../../../Actiontype/ActionType'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import SVOD from "./svod"
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { toast } from 'react-toastify'
import { user_details_web } from '../../../../actions/HomeActions'
import { ROUTES } from '../../../../constant/routes'
import * as PaymentAction from '../../../../actions/PaymentActions'
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList'
import axios from 'axios'
const right = <i className="fa fa-check-circle-o"></i>
const wrong = <i className="fa fa-times-circle"></i>

const PricingPlan2 = () => {
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const user = JSON.parse(sessionStorage.getItem('user'))
    const [selectedPlan, setSelectedPlan] = useState({})
    const [form, setForm] = useState({ select_type: location.state?.categoryType || "Plan", movie: location.state?.movie, plan: "" })
    console.log("location", location?.state)
    const [checkoutPage, setCheckoutPage] = useState(false)
    const promocode = useSelector((state) => state.payment.promocode)
    const isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'))
    const user_details = useSelector((state) => state?.home?.user_details?.data)
    const [paymentOption, setPaymentOption] = useState([])

    const { getAllPaymentOptions } = bindActionCreators(PaymentAction, dispatch)
    const { phonepePayment } = PaymentAction

    useEffect(() => {
        dispatch(user_details_web({ user_id: user?.id }))
    }, [])

    useEffect(() => {
        (async () => {
            if (location.state?.transactionType == "TVOD")
                setCheckoutPage(true)
            else
                setCheckoutPage(false)
            setForm({ select_type: location.state?.categoryType || "Plan", movie: location.state?.movie, plan: "" })
            const res = await axios.get("https://api.ipify.org/?format=json");
            const resData = await getAllPaymentOptions({ ip_address: res.data.ip })
            console.log("resData", resData)
            setPaymentOption(resData?.data)

        })()

    }, [location.state?.transactionType])
    const handleReset = () => {
        setForm({ ...form, promocode: "" })
        dispatch({ type: PROMOCODE, payload: null });
    }
    useMemo(() => {

        handleReset()
    }, [selectedPlan, location.state?.movie, location.state?.series])

    const handleStripeRedirect = () => {
        if (!isLoggedIn) {
            toast.error("Please login to continue.")
        }
        else {
            // console.log("data call", selectedPlan, promocode, user_details)
            let userObj = {
                email: user_details?.email,
                name: user_details?.firstName + " " + user_details.lastName,
                mobile: user_details?.mobileNumber,
                amount: promocode ? promocode?.data?.final_amount : selectedPlan?.country_price?.[0]?.price,
                plan: selectedPlan?.id,
                transaction_type: "SVOD",
                currency: selectedPlan?.currency || 'aud',
                promocode: promocode?.data?.id || null
            }
            history.push(ROUTES.stripePayment, { paymentData: userObj })
        }

    }
    const handleStripeRedirectTVOD = (price, adfree, currencytype, data) => {
        if (!isLoggedIn) {
            toast.error("Please login to continue.")
        }
        else {
            let userObj = {
                email: user_details?.email,
                name: user_details?.firstName + " " + user_details.lastName,
                mobile: user_details?.mobileNumber,
                amount: price,
                payment_key: data?.payment_key,
                secret_key: data?.secret_key,
                slug: location.state.redirect,
                plan: "",
                movie: location?.state?.movie || null,
                category_type: location?.state?.categoryType,
                adfree: adfree,
                transaction_type: "TVOD",
                currency: currencytype,
                series: location?.state?.series || null,
                promocode: promocode?.data?.id || null
            }
            // console.log(userObj)
            history.push(ROUTES.stripePayment, { paymentData: userObj })
        }

    }

    const handleSVOD = async () => {
        if (isLoggedIn) {
            let userObj = {
                user: user_details?.id,
                mobile_no: user_details?.mobileNumber,
                email: user_details?.email,
                promocode: promocode?.data?.id,
                gateway: "PhonePe",
                payment_amount: promocode?.data?.final_amount || selectedPlan?.country_price?.[0]?.price,
                payment_method: "PhonePe",
                device_type: "Web",
                transaction_type: "SVOD",
                ad_free: null,
                category_type: null,
                movie: "",
                series: "",
                plan: selectedPlan?.id
            }
            console.log("user: ", userObj)
            const resData = await phonepePayment(userObj)
            if (resData?.data?.success) {
                window.location.href = resData?.data?.data?.instrumentResponse?.redirectInfo?.url
            }
            else {
                toast.error("Please try again later")
            }
        }
        else {
            toast.error("Please login to continue")
        }


    }

    const handleTVOD = async () => {

        if (isLoggedIn) {
            let userObj = {
                user: user_details?.id,
                mobile_no: user_details?.mobileNumber,
                email: user_details?.email,
                promocode: promocode?.data?.id,
                gateway: "PhonePe",
                payment_amount: location?.state?.categoryType == "Series" ? promocode?.data?.final_amount || location?.state?.price : promocode?.data?.final_amount || (location.state?.price),
                payment_method: "PhonePe",
                device_type: "Web",
                transaction_type: "TVOD",
                ad_free: "true",
                category_type: location.state?.categoryType,
                movie: location.state?.movie || "",
                series: location?.state?.series || "",
                plan: null
            }
            const resData = await phonepePayment(userObj)
            if (resData?.data?.success) {
                window.location.href = resData?.data?.data?.instrumentResponse?.redirectInfo?.url
            }
            else {
                toast.error("Please try again later")
            }
        }
        else {
            toast.error("Please login to continue")
        }

    }

    return (
        <>

            <div className="site-content-contain ">
                <div id="content" className="site-content">

                    <div id="primary" className="content-area">
                        <main id="main" className="site-main">
                            <Row>
                                <Col lg="7" sm="12">
                                    {!checkoutPage ?
                                        <SVOD selectedPlan={selectedPlan} setSelectedPlan={setSelectedPlan} setCheckoutPage={setCheckoutPage} />
                                        :
                                        <div className="iq-pricing-card-two pt-5">
                                            <div className="table-responsive iq-pricing pt-2">

                                            </div>
                                            <div className="iq-price-bottom">

                                                <div className="checkout-page-table">
                                                    <Table id="my-table" className="table my-tbl" data-active="premium" >
                                                        <col></col>
                                                        <col style={{ background: "  rgb(0, 0, 0)", border: "3px solid  white" }}></col>
                                                        <thead>
                                                            <tr>
                                                                <th>{location.state?.categoryType || "Plan"} Name</th>
                                                                <td> {location.state?.movieName || location.state?.seriesName || selectedPlan?.plan_name}</td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th>{location.state?.transactionType == "TVOD" ? "Available for" : "Availability"}</th>
                                                                <td> {location.state?.transactionType == "TVOD" ? (location.state?.available_for + " days") : selectedPlan?.duration}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>{location.state?.transactionType == "TVOD" ? "Amount" : "Amount"}</th>
                                                                <td> {Number(location.state?.price || selectedPlan?.country_price?.[0]?.price).toFixed(2)}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table></div>
                                                <hr style={{ backgroundColor: "white" }} />

                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        placeholder='Type Promocode Here'
                                                        aria-label="Recipient's username"
                                                        aria-describedby="basic-addon2"
                                                        className='promocode-input'
                                                        value={form?.promocode}
                                                        onChange={(e) => setForm({ ...form, promocode: e.target.value.toUpperCase() })}
                                                        isInvalid={promocode?.statuscode == 406}
                                                    />
                                                    <Button variant="outline-secondary" style={{ color: form?.promocode && "white" }} onClick={() => {
                                                        dispatch(check_promocode({ ...form, plan: selectedPlan?.id }))
                                                    }} disabled={form.promocode ? false : true} id="button-addon2" className='promocode-button'>
                                                        Apply
                                                    </Button>
                                                    <Form.Control.Feedback type="invalid">
                                                        {promocode?.message}
                                                    </Form.Control.Feedback>
                                                </InputGroup>

                                                {promocode?.statuscode == 200 && <p className='promocode-block'><span><i className="fa fa-check" aria-hidden="true"></i>&nbsp;{promocode?.data?.promocode}</span> Applied <span>${Number(promocode?.data?.discount_amount).toFixed(2)}</span> off<span style={{ float: "right", cursor: "pointer" }} onClick={() => handleReset()}><i className="fa fa-trash" aria-hidden="true"></i> </span></p>}

                                                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                                                    <div>
                                                        {location.state?.transactionType == "TVOD" ?
                                                            <div>
                                                                {
                                                                    location?.state?.ads?.length ?
                                                                        <div className='d-flex' style={{ gap: "2rem" }}>
                                                                            <RazorpayButton className={"btn btn-hover iq-button text_small"} title={`Pay : ${location?.state?.currency || "₹"}` + Number(promocode?.data?.final_amount || location.state?.price).toFixed(2) + " (with Ads)"} transactionType={"TVOD"} promocode={promocode?.data?.id} movie={location.state?.movie} series={location?.state?.series} categoryType={location.state?.categoryType} price={promocode?.data?.final_amount || location.state?.price} ad_free={"false"} slug={location?.state?.slug} />
                                                                            <button onClick={() => handleStripeRedirectTVOD(Number(promocode?.data?.final_amount || location.state?.price), false)} className={"btn btn-hover iq-button"}>{`Pay : ${location?.state?.currency || "$"}` + Number(promocode?.data?.final_amount || location.state?.price).toFixed(2) + " (with Ads)"}</button>

                                                                            <RazorpayButton className={"btn btn-hover iq-button text_small"} title={`Pay : ${location?.state?.currency || "₹"}` + Number(promocode?.data?.final_amount || location.state?.adfreePrice).toFixed(2) + " (Ad Free)"} transactionType={"TVOD"} promocode={promocode?.data?.id} movie={location.state?.movie} series={location?.state?.series} categoryType={location.state?.categoryType} price={promocode?.data?.final_amount || location.state?.adfreePrice} ad_free={"true"} slug={location?.state?.slug} />
                                                                            <button onClick={() => handleStripeRedirectTVOD(Number(promocode?.data?.final_amount || location.state?.adfreePrice), true)} className={"btn btn-hover iq-button"}>{`Pay : ${location?.state?.currency || "$"}` + Number(promocode?.data?.final_amount || location.state?.adfreePrice).toFixed(2) + " (Ad Free)"}</button>

                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            {/* {
                                                                                paymentOption?.map((data, index) => (
                                                                                    data?.payment_provider == "Razor Pay" ?
                                                                                        <div key={index} className='d-flex' style={{ gap: "2rem" }}>
                                                                                            <RazorpayButton
                                                                                                paymentKey={data?.payment_key}
                                                                                                className={"btn btn-hover iq-button text_small"}
                                                                                                title={`Pay with ${data?.payment_provider} : ${location?.state?.currency || "₹"}` + Number(location?.state?.categoryType == "Series" ? promocode?.data?.final_amount || location?.state?.price : promocode?.data?.final_amount || (location.state?.adfreePrice || location.state?.price)).toFixed(2)}
                                                                                                transactionType={"TVOD"}
                                                                                                promocode={promocode?.data?.id}
                                                                                                movie={location.state?.movie}
                                                                                                series={location?.state?.series}
                                                                                                categoryType={location.state?.categoryType}
                                                                                                price={location?.state?.categoryType == "Series" ? promocode?.data?.final_amount || location?.state?.price : promocode?.data?.final_amount || (location.state?.adfreePrice || location?.state?.price)}
                                                                                                ad_free={"true"}
                                                                                                currency_type={data?.currency_type}
                                                                                                slug={location?.state?.slug}
                                                                                            />
                                                                                           
                                                                                        </div>
                                                                                        :
                                                                                        data?.payment_provider == "Stripe" ?
                                                                                            <div className='d-flex' style={{ gap: "2rem" }}>
                                                                                                <button
                                                                                                    onClick={() => handleStripeRedirectTVOD(
                                                                                                        Number(location?.state?.categoryType == "Series" ?
                                                                                                            promocode?.data?.final_amount || location?.state?.price
                                                                                                            :
                                                                                                            promocode?.data?.final_amount || location.state?.price)
                                                                                                        ,
                                                                                                        true,
                                                                                                        data?.currency_type,
                                                                                                        data

                                                                                                    )}
                                                                                                    className={"btn btn-hover iq-button mt-2"}
                                                                                                    style={{ fontSize: "13px" }}
                                                                                                >
                                                                                                    {`Pay with ${data?.payment_provider} : ${location?.state?.currency || "$"}` + Number(location?.state?.categoryType == "Series" ? promocode?.data?.final_amount || location?.state?.price : promocode?.data?.final_amount || location.state?.price).toFixed(2)}
                                                                                                </button>

                                                                                            </div>
                                                                                            :
                                                                                            <button
                                                                                                className={"btn btn-hover iq-button mt-2"}
                                                                                                disabled
                                                                                            >
                                                                                                Pay with {data?.payment_provider}
                                                                                            </button>

                                                                                ))
                                                                            } */}
                                                                            <button className={"btn btn-hover iq-button"} onClick={handleTVOD}>
                                                                                {`Pay Now : ${location?.state?.currency}` + Number(location?.state?.categoryType == "Series" ? promocode?.data?.final_amount || location?.state?.price : promocode?.data?.final_amount || (location.state?.price)).toFixed(2)}
                                                                            </button>
                                                                        </div>
                                                                }

                                                            </div>
                                                            :
                                                            // <div>
                                                            //     <RazorpayButton
                                                            //         className={"btn btn-hover iq-button"}
                                                            //         title={`Pay : ${location?.state?.currency || "₹"}` + Number(promocode?.data?.final_amount || selectedPlan?.country_price?.[0]?.price).toFixed(2)}
                                                            //         transactionType={"SVOD"}
                                                            //         promocode={promocode?.data?.id}
                                                            //         plan={selectedPlan?.id}
                                                            //         price={promocode?.data?.final_amount || selectedPlan?.country_price?.[0]?.price}
                                                            //         paymentKey={paymentOption?.[0]?.payment_key}
                                                            //     />
                                                            //     {/* <button onClick={() => handleStripeRedirect()} className={"btn btn-hover iq-button"}>{`Pay Now : ${selectedPlan?.country_currency_symbol || "$"}` + Number(promocode?.data?.final_amount || selectedPlan?.country_price?.[0]?.price).toFixed(2)}</button> */}
                                                            // </div>
                                                            <button
                                                                className={"btn btn-hover iq-button"}
                                                                onClick={handleSVOD}

                                                            >
                                                                {"Pay Now : ₹" + Number(promocode?.data?.final_amount || selectedPlan?.country_price?.[0]?.price).toFixed(2)}
                                                            </button>
                                                        }
                                                    </div>

                                                </div><br />
                                                <div style={{ cursor: "pointer", textAlign: "center" }} onClick={() => location.state?.transactionType == "TVOD" ? history.goBack() : setCheckoutPage(prev => !prev)}><i className="fa fa-arrow-left" aria-hidden="true"></i>  Go back</div>

                                            </div>
                                        </div>}
                                </Col>
                                <Col lg="5" sm="12">
                                    <div className='marquee'>
                                        <img src={subscriptionBG} width={"100%"} />
                                        <img src={subscriptionBG} width={"100%"} />

                                    </div>
                                    <div className='overlay-shadow'>
                                        <img src={LoadingGIF} width={"200px"} />
                                        <p className='mt-3'>{location.state?.transactionType == "TVOD" ? "Buy" : "Subscribe"} now and start streaming</p>
                                    </div>
                                </Col>


                            </Row>
                        </main>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PricingPlan2;