import { Switch, Route } from 'react-router-dom'
import Layout1 from '../layouts/backend/layout1'
import BlankLayout from '../layouts/blanklayout';
import VideoFile from '../player/VideoFile';
import { ROUTES } from '../constant/routes';
import StreamFile from '../player/StreamFile';
import TrailerFile from '../player/TrailerFile';
import EpisodeFile from '../player/EpisodeFile';
import SignUp from '../views/backend/pages/auth/signup'
import Login from '../views/backend/pages/auth/signin'
import Video from '../views/backend/pages/video'
import RecoverPswd from '../views/backend/pages/auth/recover-pswd'
import profile_selection from '../views/backend/pages/auth/profile_selection';
import profile_manage from '../views/backend/pages/auth/profile_manage';
import profile_pin from '../views/backend/pages/auth/profile_pin'
import Faq from '../views/backend/pages/faq';
import AlertMessage from '../views/backend/common/AlertMessage';
import profile from '../views/backend/pages/auth/profile';
import Paypal from '../razorpay/Paypal';
import Loading from '../player/Loading';
import Phonepe from '../razorpay/Phonepe';
const LayoutsRoute = props => {
    return (
        <Switch>
            <Route path="/watch-video" component={Video} />
            <Route path="/login" component={Login} />
            <Route path={ROUTES.firebaseotp} component={Faq} />
            <Route path="/profile" component={profile} />
            <Route path="/recover-pswd" component={RecoverPswd} />
            <Route path="/profile_selection" component={profile_selection} />
            <Route path="/profile_manage" component={profile_manage} />
            <Route path="/profile_pin" component={profile_pin} />
            {/* <Route path="/auth" component={BlankLayout} /> */}
            <Route path={`${ROUTES.phonepe}/:id`} component={Phonepe} />

            <Route path={ROUTES.video} component={VideoFile} />
            <Route path={ROUTES.stream} component={StreamFile} />
            <Route path={ROUTES.trailer} component={TrailerFile} />
            <Route path={ROUTES.episode} component={EpisodeFile} />
            <Route path={ROUTES.paypal} component={Paypal} />
            <Route path={ROUTES.loading} component={Loading} />

            <Route path="/" component={Layout1} />
        </Switch>
    )
}

export default LayoutsRoute