// import React from 'react'
import React, { useState, useEffect, useMemo } from 'react'
import { Container, Button, Row, Col, Form, Dropdown, DropdownButton } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import "./auth/form.css"
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useDispatch } from 'react-redux';
import { complaint_create, user_details_web } from "../../../actions/HomeActions"
import { useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { IMAGE_ENDPOINT } from '../../../constant/ApiList';
import { toast } from 'react-toastify';
import * as MovieAction from '../../../actions/MovieAction'
import AlertMessage from '../common/AlertMessage';
import { CircularProgress } from '@mui/material';


export default function MovieDemandForm(props) {

    const user = JSON.parse(sessionStorage.getItem('user'))
    const dispatch = useDispatch()
    let history = useHistory()
    const [show, setShow] = useState(false);
    const [messageTimeout, setMessageTimeout] = useState(false)
    const profile = JSON.parse(sessionStorage.getItem('profile'))
    const user_details = useSelector((state) => state.home.user_details?.data)
    const [selectedValue, setSelectedValue] = useState("Select Movie Language")
    const [image, setImage] = useState("")
    const { getAllLanguageMovie, submitDemandedForm } = MovieAction
    const [language, setLanguage] = useState([])
    const [form, setForm] = useState({
        user_id: user?.id
    })

    const [num1, setNum1] = useState(0);
    const [num2, setNum2] = useState(0);
    const [answer, setAnswer] = useState('');
    const [userInput, setUserInput] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        (async () => {
            generateMathProblem();
            const resData = await getAllLanguageMovie()
            console.log(resData)
            if (resData?.statuscode == 200) {
                setLanguage(resData?.data)
            }
        })()
    }, [])

    useEffect(() => {
        dispatch(user_details_web({ user_id: user?.id }))
    }, [])

    const generateMathProblem = () => {
        const randNum1 = Math.floor(Math.random() * 10) + 1;
        const randNum2 = Math.floor(Math.random() * 10) + 1;
        setNum1(randNum1);
        setNum2(randNum2);
        setAnswer(randNum1 + randNum2);
        setUserInput('');
        setErrorMessage('');
    };

    const handleChange = (event) => {
        setUserInput(event.target.value);
    };




    const handleComplaintSubmit = async (event) => {
        event.preventDefault();
        setLoader(true)
        if (form?.movie_name && form?.language_id) {
            if (parseInt(userInput) === answer) {
                const resData = await submitDemandedForm(form)
                console.log(resData)
                if (resData?.statuscode == 200) {
                    setLoader(false)
                    toast.success(resData?.message)
                    setTimeout(() => {
                        history.push('/')
                    }, 1500)
                }
                else {
                    setLoader(false)
                    toast.success(resData?.message)
                }
            } else {
                setLoader(false)

                setErrorMessage('Incorrect answer, please try again.');
            }


        }
        else {
            toast.error("All fields are required")

        }
    }
    const complaint_message = useSelector((state) => state.home.complaint_message)
    useMemo(() => {

        setMessageTimeout(true)

        setTimeout(() => { setMessageTimeout(false) }, 2000)
    }, [complaint_message])

    const handleValue = (e, id) => {
        console.log(id)
        form.language_id = id
        setSelectedValue(e.target.value)
    }

    console.log(form)
    return (
        <>
            <AlertMessage />
            <section style={{ paddingBottom: "0" }} className="m-profile setting-wrapper  set-margin-responsive1">
                <Container>
                    <Row style={{ alignItems: "center" }}>
                        <Col lg="4" className="">
                            <h5 className="main-title mb-4">Request your movie</h5>

                            <div className="sign-user_card text-center">
                                <img style={{ height: "150px" }} src={IMAGE_ENDPOINT + profile?.avatar?.avatarImage} className="rounded-circle img-fluid d-block mx-auto mb-3" alt="user" />
                                <h4 className="mb-3">{user_details?.firstName + " " + user_details?.lastName}</h4>
                                <p>{user_details?.email}</p>
                                <p>{user_details?.mobileNumber}</p>
                            </div>
                        </Col>
                        <Col lg="8">
                            <div className='mb-2' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                <div>
                                    <h5 className="main-title pt-3">You Demand, We Deliver</h5>
                                    <p className='mt-2' style={{ color: "grey" }}>We are making an effort to satisfy our customers' desire to view the films of their choice. By providing the necessary information, you can request the films of your choice, and we'll do our best to comply. </p>
                                </div>
                            </div>
                            <div className="" style={{ border: "1px solid #ffffff", padding: "20px", borderRadius: "0.7rem" }}>
                                <Form onSubmit={(event) => handleComplaintSubmit(event)}>
                                    <Row >

                                        <Col md="6" >
                                            <Form.Group>
                                                {/* <Form.Label style={{ color: "white" }}>Enter Movie/Web Series Name</Form.Label> */}
                                                <Form.Control type="text" onChange={(e) => setForm({ ...form, movie_name: e.target.value })} style={{ borderRadius: "1rem" }} className="mb-0" id="exampleInputEmail3" placeholder="Enter Movie/Web Series Name" autoComplete="off" required />

                                            </Form.Group>
                                        </Col>
                                        <Col md="6" >
                                            <Form.Group className='btn-change drpdwn-colour'>
                                                {/* <Form.Label style={{ display: "flex", color: "white" }}> Select Complaint type</Form.Label> */}
                                                <DropdownButton id="dropdown-item-button" className='mt-2' title={selectedValue}>
                                                    {
                                                        language?.map((data, index) => (
                                                            <Dropdown.Item key={index} as="option" style={{ cursor: "pointer", color: "white" }} value={data?.language_name} onClick={(e) => handleValue(e, data?.id)}>{data?.language_name}</Dropdown.Item>
                                                        ))
                                                    }
                                                </DropdownButton>
                                            </Form.Group>
                                        </Col>
                                        <Col md="6" >
                                            <Form.Group>
                                                <Form.Label style={{ color: "white" }}>  What is {num1} + {num2}?</Form.Label>
                                                <Form.Control type="text" onChange={handleChange} style={{ borderRadius: "1rem" }} className="mb-0" id="exampleInputEmail3" placeholder="Enter the value" autoComplete="off" required />
                                                {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

                                            </Form.Group>
                                        </Col>

                                    </Row>

                                    {/* {complaint_message?.message && messageTimeout && (complaint_message?.statuscode == 200 ?
                                        <Alert variant="success" style={{ background: "rgb(0,255,0,0.1)", borderColor: "rgb(0,255,0)" }} >
                                            <p style={{ color: "rgb(0,255,0)", fontWeight: "500", fontSize: "14px" }}>
                                                {complaint_message?.message}
                                            </p>
                                        </Alert>
                                        : <Alert variant="danger" style={{ background: "rgb(255,0,0,0.1)", borderColor: "rgb(255,0,0)" }} >
                                            <p style={{ color: "rgb(255,0,0)", fontWeight: "500", fontSize: "14px" }}>
                                                {complaint_message?.message}
                                            </p>
                                        </Alert>)} */}
                                    {
                                        loader ?
                                            <div style={{textAlign:"center"}}>
                                                <CircularProgress sx={{color:"#d057a0"}}/>
                                            </div>
                                            :
                                            <Button type='submit' style={{ display: "block", margin: "0 auto" }} className="btn btn-hover" >Submit</Button>

                                    }
                                </Form>
                            </div>

                        </Col>

                        {/* 
                        <div>
                            <label>
                                What is {num1} + {num2}?
                                <input
                                    type="text"
                                    value={userInput}
                                    onChange={handleChange}
                                    required
                                />
                            </label>
                        </div>
                        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                        <button onClick={handleSubmit}>Submit</button> */}

                    </Row>
                    <Row>
                        <span style={{ marginTop: "9rem", fontSize: "1.12rem", marginLeft: "-3.4rem" }}>For any quary, you can contact us at gujjustream@gmail.com</span>
                    </Row>
                </Container>
            </section>

        </>
    )
}
