import React, { useState, useEffect, useMemo } from 'react'
import { Container, Button, Row, Col, Form } from 'react-bootstrap'

import { Link, useHistory } from 'react-router-dom'
import "./form.css"
// rtl 
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { rtlModeAction, getRtlMode } from '../../../../store/mode/rtlmode'
import { useDispatch } from 'react-redux';
import bg from "../../../../assets/images/signup_background.jpg"
import user_pic from "../../../../assets/images/user/user.jpg"
import { IMAGE_ENDPOINT } from '../../../../constant/ApiList';
import { user_details_web } from '../../../../actions/HomeActions';
import { ROUTES } from '../../../../constant/routes';
import device_restristion from '../../../../assets/regal_images/Device Restriction.png'
import transaction from '../../../../assets/regal_images/transaction-history.png'
import profile_img from '../../../../assets/regal_images/group.png'
import StarsIcon from '@mui/icons-material/Stars';
import change_password from '../../../../assets/regal_images/Change Pswd.png'
import GroupIcon from '@mui/icons-material/Group';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DevicesIcon from '@mui/icons-material/Devices';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Grid } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EditIcon from '@mui/icons-material/Edit';
import giftImg from '../../../../assets/regal_images/gift.png'



const mapStateToProps = (state) => {
    return {
        rtlMode: getRtlMode(state)
    };


}
const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            rtlModeAction
        },
        dispatch
    )
})
const My_Account = (props) => {
    const user = JSON.parse(sessionStorage.getItem('user'))
    const profile = JSON.parse(sessionStorage.getItem('profile'))
    console.log(user, "acc")
    const dispatch = useDispatch()
    let history = useHistory()
    const [show, setShow] = useState(false);
    const user_details = useSelector((state) => state.home.user_details?.data)
    const user_plan_expired = useSelector((state) => state.home.user_details)

    //     useEffect(() => {
    // const rtlMode = sessionStorage.getItem('rtl-mode');
    //     if(rtlMode===null){
    //         props.rtlModeAction(props.rtlMode)
    //     }
    //     else{
    //         props.rtlModeAction(rtlMode);
    //     }
    //         })
    useEffect(() => {

        dispatch(user_details_web({ user_id: user?.id }))

    }, [])
    console.log(user_plan_expired, "my_account")

    const handleClick = () => {
        history.push('/profile_selection', { back: true })
    }

    return (
        <>

            {/* <section className="sign-in-page" style={{ marginTop: "5rem" }}>

                <Container >
                    <Row className="justify-content-center align-items-center height-self-center">
                        <Col lg="7" md="12" className="align-self-center">
                            <div className="sign-user_card ">
                                <div className="sign-in-page-data">
                                    <div className="sign-in-from w-100 m-auto">


                                        <p style={{ textAlign: "center", marginBottom: "1rem", fontWeight: "400", fontSize: "24px" }}>My Account</p>
                                        <Row >


                                            <Col md="12" >
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div style={{ display: "flex" }}>
                                                        <div style={{ marginRight: "1rem" }}>
                                                            <img src={IMAGE_ENDPOINT + profile?.avatar?.avatarImage} style={{ maxWidth: "75px" }} className="rounded img-fluid d-block mx-auto mb-3" alt="user" />

                                                        </div>
                                                        <div>
                                                            <p style={{ marginBottom: "0rem" }}>Name   : <span style={{ color: "white" }}>{user_details?.firstName + " " + user_details?.lastName}</span></p>
                                                            <p style={{ marginBottom: "0rem" }}>E-mail : <span style={{ color: "white" }}>{user_details?.email}</span></p>
                                                            <p style={{ marginBottom: "0rem" }}>Mobile : <span style={{ color: "white" }}>{user_details?.mobileNumber}</span></p>

                                                        </div>
                                                    </div>
                                                    <Link to={ROUTES.edit_account} style={{ marginBottom: "0rem", color: "#ffc328" }}>
                                                        <i className="fa fa-pencil-square" style={{ fontSize: "40px" }} aria-hidden="true"></i>

                                                    </Link>

                                                </div>
                                            </Col>

                                        </Row>
                                        <Row >
                                            <Col md="12" >
                                                <div style={{ border: "grey 1px solid", padding: "1rem", borderRadius: "1rem" }}>

                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <p>Current Plan : {user_details?.subscription ? <span style={{ color: "#ffc328" }}>{user_details?.subscription?.plan_name}</span> : <span style={{ color: "red" }}>Not Subscribed</span>}</p>
                                                        <p>Expired On : {user_details?.subscription ? <span style={{ color: "#ffc328" }}>{new Date(user_plan_expired?.expiry_date).toLocaleDateString('en-GB', { timeZone: 'Europe/London' })}</span> : <span style={{ color: "red" }}>Not Subscribed</span>}</p>

                                                    </div>
                                                    <div className="align-items-center r-mb-23 justify-content-center" style={{ display: "flex" }} data-animation-in="fadeInUp" data-delay-in="1.3">
                                                        <Link to="/price-plan" className="btn btn-hover iq-button" style={{ width: "100%" }}>{user_details?.subscription ? "Upgrade Plan" : "Subscribe"}</Link>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md="12" >
                                                <div style={{ padding: "1rem" }}>
                                                    <Link to="/change_password" style={{ display: "block" }}>
                                                        <div className="media align-items-center">
                                                            <div className="right-icon">
                                                                <img src={change_password} alt='acc' className='menu-img' />
                                                            </div>
                                                            <div className="media-body ml-3">
                                                                <p className="my-0 ">Change Password</p>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col md="12" >
                                                <div style={{ borderTop: "grey 1px solid", padding: "1rem", cursor: "pointer" }}>
                                                    <div onClick={handleClick} style={{ display: "block" }}>
                                                        <div className="media align-items-center">
                                                            <div className="right-icon">
                                                                <img src={profile_img} alt='acc' className='menu-img' />
                                                            </div>
                                                            <div className="media-body ml-3">
                                                                <p className="my-0 ">Manage Profiles</p>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col md="12" >
                                                <div style={{ borderTop: "grey 1px solid", padding: "1rem" }}>
                                                    <Link to="/transaction_history" style={{ display: "block" }}>
                                                        <div className="media align-items-center">
                                                            <div className="right-icon">
                                                                <img src={transaction} alt='acc' className='menu-img' />
                                                            </div>
                                                            <div className="media-body ml-3">
                                                                <p className="my-0 ">Transaction History</p>
                                                            </div>
                                                        </div>
                                                    </Link>


                                                </div>
                                            </Col>
                                        </Row>
                                        <Row >
                                            <Col md="12" >
                                                <div style={{ borderTop: "grey 1px solid", padding: "1rem" }}>
                                                    <Link to="/device_management" style={{ display: "block" }}>
                                                        <div className="media align-items-center">
                                                            <div className="right-icon">
                                                                <img src={device_restristion} alt='acc' className='menu-img' />
                                                            </div>
                                                            <div className="media-body ml-3">
                                                                <p className="my-0 ">Device Management</p>
                                                            </div>
                                                        </div>
                                                    </Link>


                                                </div>
                                            </Col>
                                        </Row>



                                    </div>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            <section className="m-profile set-margin-responsive1 setting-wrapper">
                <Container>
                    <Row style={{ alignItems: "center" }}>
                        <Col lg="4" className="">
                            <h5 className="main-title mb-4">My Account</h5>

                            <div className="sign-user_card text-center">
                                <img style={{ height: "150px" }} src={IMAGE_ENDPOINT + profile?.avatar?.avatarImage} className="rounded-circle img-fluid d-block mx-auto mb-3" alt="user" />
                                <h4 className="mb-3">{user_details?.firstName + " " + user_details?.lastName}</h4>
                                <p style={{ display: "flex", alignItems: "center" }}><LocationOnIcon /> <span>{user_details?.userLocation}</span></p>
                            </div>
                        </Col>
                        <Col lg="8">
                            <div className='mb-2 mt-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                                <h5 className="main-title">Personal Details</h5>
                                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                    {/* <Link to={ROUTES.rewards}>
                                        <EmojiEventsIcon style={{ width:"46px",height:"37px",padding:"3px",borderRadius:"5px",color:"black",backgroundColor:"#d057a0" }}/>
                                     </Link> */}

                                    <Link to={ROUTES.edit_account}>
                                        <EditIcon style={{ width: "44px", height: "37px", padding: "3px", borderRadius: "5px", color: "black", backgroundColor: "#ffc328" }} />
                                    </Link>
                                </div>
                            </div>
                            <div className="" style={{ border: "1px solid #ffffff", padding: "20px", borderRadius: "0.7rem" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        Email
                                    </Grid>
                                    <Grid item xs={8}>
                                        {user_details?.email}
                                    </Grid>
                                    <Grid item xs={4}>
                                        Mobile No.
                                    </Grid>
                                    <Grid item xs={8}>
                                        {user_details?.mobileNumber}
                                    </Grid>
                                    <Grid item xs={4}>
                                        Date of Birth
                                    </Grid>
                                    <Grid item xs={8}>
                                        {new Date(user_details?.birthDate).toLocaleDateString('en-GB', { timeZone: 'Europe/London' })}
                                    </Grid>
                                </Grid>
                            </div>
                            {/* <h5 className="main-title mb-2 mt-3">Plan Details</h5> */}
                            <div className="mt-2" style={{ border: "1px solid #ffffff", padding: "20px", borderRadius: "0.7rem" }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        Current Plan : {user_details?.subscription ? <span style={{ color: "#ffc328" }}>{user_details?.subscription?.plan_name}</span> : <span style={{ color: "red" }}>No subscription found.</span>}
                                    </Grid>
                                    <Grid item xs={6} style={{ textAlign: "end" }}>
                                        Expires : {user_details?.subscription ? <span style={{ color: "#ffc328" }}>{new Date(user_plan_expired?.expiry_date).toLocaleDateString('en-GB', { timeZone: 'Europe/London' })}</span> : <span style={{ color: "red" }}>No subscription found.</span>}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Link to="/price-plan" className="btn btn-hover iq-button" style={{ width: "100%" }}>{user_details?.subscription ? "Upgrade Plan" : "Subscribe"}</Link>
                                    </Grid>
                                    {/* <Grid item xs={6} >
                                        <Link to="/change_password">
                                            <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                                <Grid item style={{ textAlign: "end" }} xs={4}>
                                                    <LockOpenIcon />
                                                </Grid>
                                                <Grid item style={{ textAlign: "left" }} xs={8}>
                                                    <span className='pl-2'>Change Password</span>
                                                </Grid>
                                            </div>
                                        </Link>
                                    </Grid> */}
                                    <Grid item xs={6}>
                                        <div onClick={handleClick} style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem", cursor: "pointer" }}>
                                            <Grid item style={{ textAlign: "end" }} xs={4}>
                                                <GroupIcon />
                                            </Grid>
                                            <Grid item style={{ textAlign: "left" }} xs={8}>
                                                <span className='pl-2'>Manage Profiles</span>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Link to="/transaction_history">

                                            <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                                <Grid item style={{ textAlign: "end" }} xs={4}>
                                                    <ReceiptLongIcon />
                                                </Grid>
                                                <Grid item style={{ textAlign: "left" }} xs={8}>
                                                    <span className='pl-2'>Transaction History</span>
                                                </Grid>
                                            </div>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Link to="/device_management">

                                            <div style={{ display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                                <Grid item style={{ textAlign: "end" }} xs={4}>
                                                    <DevicesIcon />
                                                </Grid>
                                                <Grid item style={{ textAlign: "left" }} xs={8}>
                                                    <span className='pl-2'>Device Management</span>
                                                </Grid>
                                            </div>
                                        </Link>
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                        <div onClick={() => history.push(ROUTES.rewards)} style={{ cursor: "pointer", display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                            <Grid item style={{ textAlign: "end" }} xs={4}>
                                                <StarsIcon />
                                            </Grid>
                                            <Grid item style={{ textAlign: "left" }} xs={8}>
                                                <span className='pl-2'>Manage Coins</span>
                                            </Grid>
                                        </div>
                                    </Grid> */}
                                    {/* <Grid item xs={6}>
                                        <div onClick={()=> history.push(ROUTES.qrdetail)} style={{ cursor: "pointer", display: "flex", justifyContent: "center", padding: "10px", alignItems: "center", border: "1px solid #ffffff", borderRadius: "1rem" }}>
                                            <Grid item style={{ textAlign: "end" }} xs={4}>
                                                <img src={giftImg} alt='acc' className='menu-img' />
                                            </Grid>
                                            <Grid item style={{ textAlign: "left" }} xs={8}>
                                                <span className='pl-2'>Offers & Rewards</span>
                                            </Grid>
                                        </div>
                                    </Grid> */}

                                </Grid>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(My_Account)